/** Reset some basic elements */
body, h1, h2, h3, h4, h5, h6, p, blockquote, pre, hr, dl, dd, ol, ul, figure { margin: 0; padding: 0; }

/** Basic styling */
body {
    background-color: #d8d8d8;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  

/** Set `margin-bottom` to maintain vertical rhythm */
h1, h2, h3, h4, h5, h6, p, blockquote, pre, ul, ol, dl, figure, .highlight { margin-top: 19px; margin-bottom: 19px; }

/** Headings */
h1, h2, h3, h4, h5, h6 { font-weight: 200;  color: #111; text-transform: capitalize; text-align: left; }

.site-title { text-align: center; font-size: 250%; color: #d8d8d8; text-decoration: none; }

.title { text-align: center; font-size: 75%;  line-height: 1; }

.value { text-align: center;  line-height: 1; }
